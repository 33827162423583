type Props = {
  className?: string
  label?: string
}

const BackButton = ({ className, label }: Props): JSX.Element => {
  const handleBack = () => {
    window.history.back()
  }

  return (
    <div className={`inline-flex items-center gap-2 ${className}`}>
      <button
        onClick={handleBack}
        className="inline-flex items-center gap-2 rounded-lg px-1 py-2 text-sm font-medium text-grey-dark transition-colors hover:underline focus:outline-none"
        type="button"
        aria-label="Go back to previous page"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
        >
          <path
            d="M15 8H1M1 8L8 15M1 8L8 1"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {label}
      </button>
    </div>
  )
}

export default BackButton
