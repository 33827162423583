import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { TooltipProps } from '../types/address-types'

const Tooltip = (props: TooltipProps) => {
  const { text, darkTooltipFontColor } = props
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const toggleTooltip = () => {
    setIsTooltipVisible(!isTooltipVisible)
  }

  return (
    <span className="relative ml-2 cursor-pointer">
      <QuestionMarkCircleIcon
        tabIndex={0}
        onFocus={toggleTooltip}
        onBlur={toggleTooltip}
        onMouseEnter={toggleTooltip}
        onMouseLeave={toggleTooltip}
        className="h-4 w-4 text-dark-blue-primary"
      />
      <span
        className={`absolute bottom-full min-w-[280px] rounded-lg border border-grey-400 bg-white p-2 text-xs font-normal ${darkTooltipFontColor ? 'text-black' : 'text-grey-400'} ${isTooltipVisible ? '' : 'hidden'}`}
      >
        {text}
      </span>
    </span>
  )
}

export default Tooltip
