import { epiGetCategory } from '@/api/content-delivery'
import { CategoryContentModel } from '@/api/types/content-delivery-types'
import { viewItemListGTM } from '@/components/shared/analytics/gtm-manager'
import SharedButton from '@/components/shared/buttons/shared-button'
import { RenderContentArea } from '@/epi/pageUtils/render-content-area'
import withHydration from '@/lib/withHydration'
import { useHeaderSearchStore } from '@/stores/header-search-store'
import { useSearchStore } from '@/stores/search-store'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ProductListingFilters from './components/product-listing-filters'
import ProductListingSort from './components/product-listing-sort'
import ProductTileGrid from './components/product-tile-grid'
import { CategoryModel, ProductListingPageProps } from './types/plp-types'

const ProductListingPage = (props: Readonly<ProductListingPageProps>) => {
  const { model } = props
  const {
    isLoading,
    setIsLoading,
    page,
    setPage,
    setPageUrl,
    setCategoryId,
    setSearchTerm,
    setIsLoadMore,
    setActiveCategory,
    fetchResults,
    products,
    hasMoreProducts,
    resetProducts,
    facetGroups,
    setSelectedFacets,
    selectedFacets,
    orderBy,
    setOrderBy,
  } = useSearchStore()

  const { setHeaderSearchTerm } = useHeaderSearchStore()
  const [searchParams, setSearchParams] = useSearchParams()
  const categoryId = props.model.categoryId ?? searchParams.get('id')
  const [category, setCategory] = useState<CategoryModel>()
  const updateUrlAndFetchResults = async (
    page?: number,
    removeProductPage = false,
    selectedFacets?: string,
    newOrderBy?: number,
  ) => {
    try {
      setIsLoading(true)

      // prepare state updates from parameters passed for
      const updates = new Map()
      if (page) updates.set('page', page)
      if (selectedFacets !== undefined) updates.set('selectedFacets', selectedFacets)
      if (newOrderBy !== undefined) updates.set('orderBy', newOrderBy)

      // update URL parameters
      const newSearchParams = new URLSearchParams(searchParams)

      if (page && !removeProductPage) {
        newSearchParams.set('productPage', page.toString())
      } else if (removeProductPage) {
        newSearchParams.delete('productPage')
      }

      if (selectedFacets !== undefined) {
        newSearchParams.delete('fkey')
        if (selectedFacets) {
          selectedFacets.split(',').forEach((facet) => {
            newSearchParams.append('fkey', facet)
          })
        }
      }

      const sortValue = newOrderBy ?? orderBy
      if (sortValue !== 0) {
        newSearchParams.set('orderBy', sortValue.toString())
      } else {
        newSearchParams.delete('orderBy')
      }

      // update url without browser page refresh
      window.history.pushState(
        { searchParams: newSearchParams.toString() },
        '',
        `${window.location.pathname}?${newSearchParams.toString()}`,
      )

      // apply all state updates
      updates.forEach((value, key) => {
        switch (key) {
          case 'page':
            setPage(value)
            break
          case 'selectedFacets':
            setSelectedFacets(value)
            break
          case 'orderBy':
            setOrderBy(value)
            break
        }
      })

      await fetchResults()
    } catch (error) {
      console.error('Error updating results:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleClearSelections = async () => {
    try {
      setIsLoading(true)
      setSelectedFacets('')
      setPage(1)
      setOrderBy(0)
      resetProducts()

      // Creating fresh URL parameters instead of modifying existing ones
      const newSearchParams = new URLSearchParams()

      // Preserve non-filter parameters like categoryId
      const categoryParam = searchParams.get('id')
      if (categoryParam) {
        newSearchParams.set('id', categoryParam)
      }

      // Using replaceState to avoid adding a new history entry and to keep the URL clean
      // Helps fix the issue where the browser back button / external link would not work as expected
      window.history.replaceState(
        { searchParams: newSearchParams.toString() },
        '',
        `${window.location.pathname}${newSearchParams.toString() ? '?' + newSearchParams.toString() : ''}`,
      )

      // Use a clean fetch without any filter parameters
      // Need to use fetchResults instead of updateUrlAndFetchResults to keep URL correct
      await fetchResults()
    } catch (error) {
      console.error('Error clearing selections:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const loadMore = async () => {
    const newPage = page + 1
    setPage(newPage)
    setIsLoadMore(true)
    await updateUrlAndFetchResults(newPage)
  }

  const handleSearchParams = () => {
    const term = searchParams.get('q')
    if (term) {
      setSearchTerm(term)
    }
    setHeaderSearchTerm('')
  }

  const applyOrClearFilters = async (selectedFacets: string) => {
    if (selectedFacets == '') {
      // setSelectedFacets('')
      await handleClearSelections()
      return
    }
    setSelectedFacets(selectedFacets)
    setPage(1)
    resetProducts()
    await updateUrlAndFetchResults(undefined, true, selectedFacets)
  }

  const applyOrderBy = async (newOrderBy: number) => {
    setOrderBy(newOrderBy)
    setPage(1)
    resetProducts()
    await updateUrlAndFetchResults(undefined, true, selectedFacets, newOrderBy)
  }

  function categoryContentModelToCategoryModel(categoryContentModel: CategoryContentModel): CategoryModel {
    return {
      contentId: `${categoryContentModel.contentLink.id}`,
      displayName: categoryContentModel.displayName,
      name: categoryContentModel.name,
      code: categoryContentModel.code,
    } as CategoryModel
  }

  // Initial page setup effect
  useEffect(() => {
    if (!categoryId) return

    const initializePage = async () => {
      try {
        resetProducts()
        setIsLoadMore(false)

        const categoryResponse = await epiGetCategory(categoryId)
        if (!categoryResponse) {
          console.warn('No category found for ID:', categoryId)
          return
        }
        setCategory(categoryContentModelToCategoryModel(categoryResponse))

        setCategoryId(categoryId)
        setPageUrl(model.pageUrl)
        setActiveCategory('products')

        handleSearchParams()

        // Set URL-based state
        const pageNumberFromQuery = searchParams.get('productPage')
        const urlFacets = searchParams.getAll('fkey')
        const urlOrderBy = searchParams.get('orderBy')

        // Update store with URL values
        setPage(pageNumberFromQuery ? parseInt(pageNumberFromQuery) : 1)
        setSelectedFacets(urlFacets.join(','))
        setOrderBy(urlOrderBy ? parseInt(urlOrderBy) : 0)

        await fetchResults()
      } catch (error) {
        console.error('Error initializing product listing page:', error)
      }
    }

    initializePage()
  }, [categoryId]) // Only depend on categoryId changes

  useEffect(() => {
    const handleUrlChanges = async () => {
      if (!categoryId) return

      const pageNumberFromQuery = searchParams.get('productPage')
      const urlFacets = searchParams.getAll('fkey')
      const urlOrderBy = searchParams.get('orderBy')

      // batch state updates
      setPage(pageNumberFromQuery ? parseInt(pageNumberFromQuery) : 1)
      setSelectedFacets(urlFacets.join(','))
      setOrderBy(urlOrderBy ? parseInt(urlOrderBy) : 0)
    }

    handleUrlChanges()
  }, [searchParams, categoryId, setOrderBy, setPage, setSelectedFacets])

  // browser navigation handler effect
  useEffect(() => {
    const handlePopstate = async () => {
      if (!categoryId) return

      resetProducts()
      setIsLoadMore(false)
      await fetchResults()
    }

    window.addEventListener('popstate', handlePopstate)
    return () => window.removeEventListener('popstate', handlePopstate)
  }, [categoryId])

  useEffect(() => {
    if (!products.length) return
    viewItemListGTM(products, 'product_catalogue')
  }, [products])

  return (
    <div className="container mt-8 flex flex-col py-0 font-mulish">
      <div className="flex flex-col">
        <div className="flex w-full flex-col justify-between gap-[12px] pb-7">
          <h1 className="text-2xl font-bold text-grey-dark lg:text-4xl">{category?.displayName}</h1>
        </div>
        {RenderContentArea(model.topContentArea)}
        {category && (
          <div className="flex grid-cols-8 flex-col gap-7 md:grid">
            <div className="flex-auto md:col-span-2">
              <div className="h-auto text-grey-dark">
                <ProductListingFilters
                  facetGroups={facetGroups}
                  applyFilters={applyOrClearFilters}
                  clearSelections={handleClearSelections}
                  localizations={model.localizations}
                />
              </div>
              <div className="reduced-font mt-5">{RenderContentArea(props.model.underFiltersContentArea)}</div>
            </div>
            <div className="flex-1 md:col-span-6">
              <ProductListingSort
                orderBy={orderBy}
                onOrderByChange={applyOrderBy}
                setOrderBy={setOrderBy}
                localizations={model.localizations}
              />
              <div className="flex flex-col gap-4">
                <ProductTileGrid products={products} itemListId="product_catalogue" />
              </div>
              {(!products || products.length === 0) && !isLoading && <div>{model.localizations?.emptyMessage}</div>}
              <div className="flex flex-col gap-4">
                <div className="mt-5 flex items-center justify-center">
                  {hasMoreProducts && products && products.length > 0 && (
                    <SharedButton onClick={loadMore} style="primary">
                      <span>{model.localizations?.loadMore}</span>
                    </SharedButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {RenderContentArea(model.bottomContentArea)}
      </div>
    </div>
  )
}

export default withHydration(ProductListingPage)
