import { FacetGroupProps } from '@/components/pages/search/types/search-types'
import SharedButton from '@/components/shared/buttons/shared-button'
import { EIGHTH, FIFTH, FIRST, FOURTH, NINTH, SECOND, SEVENTH, SIXTH, TENTH, THIRD } from '@/lib/constants'
import { replaceNonXmlWithSpace, trimSpace } from '@/lib/currency/formatting'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { uid } from 'uid'
import { ProductListingPageLocalizations } from '../types/plp-types'

interface ProductListingFiltersProps {
  facetGroups: FacetGroupProps[]
  applyFilters: (selectedFacets: string) => void
  clearSelections: () => void
  localizations: ProductListingPageLocalizations | undefined
}

const ProductListingFilters: React.FC<ProductListingFiltersProps> = ({
  facetGroups,
  applyFilters,
  clearSelections,
  localizations,
}) => {
  const [selectedFacets, setSelectedFacets] = useState<string[]>([])
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  // Sync facet state with URL parameters on initial render and URL changes
  useEffect(() => {
    const urlFacets = searchParams.getAll('fkey')
    setSelectedFacets(urlFacets)
  }, [searchParams]) // Depend on searchParams if user navigates back/forward or from external link

  // Sync facet state with facetGroups prop changes
  useEffect(() => {
    const updatedSelectedFacets = facetGroups.reduce((acc: string[], group) => {
      if (group.facets) {
        if (group.displayMode === 1) {
          // Checkbox mode
          group.facets.forEach((facet) => {
            if (selectedFacets.includes(facet.key) || facet.selected) {
              acc.push(facet.key)
            }
          })
        } else if (group.displayMode === 10) {
          // Dropdown mode
          const selectedFacet = group.facets.find((facet) => selectedFacets.includes(facet.key) || facet.selected)
          selectedFacet && acc.push(selectedFacet.key)
        }
      }
      return acc
    }, [])

    if (JSON.stringify(updatedSelectedFacets) !== JSON.stringify(selectedFacets)) {
      setSelectedFacets(updatedSelectedFacets)
    }
  }, [facetGroups]) // Only depend on facetGroups prop

  const handleCheckboxChange = (facetKey: string) => {
    setSelectedFacets((prevSelectedFacets) => {
      return prevSelectedFacets.includes(facetKey)
        ? prevSelectedFacets.filter((key) => key !== facetKey)
        : [...prevSelectedFacets, facetKey]
    })
  }

  const handleSelectChange = (facetKey: string) => {
    setSelectedFacets((prevSelectedFacets) => {
      const currentKeyPrefix = facetKey.split(':')[0]
      const updatedFacets = prevSelectedFacets.filter((key) => !key.startsWith(`${currentKeyPrefix}:`))

      return facetKey.includes('default') ? updatedFacets : [...updatedFacets, facetKey]
    })
  }

  const handleApplyFilters = () => {
    applyFilters(selectedFacets.join(','))
  }

  const handleClearSelections = () => {
    setSelectedFacets([])
    clearSelections()
  }

  const getSelectValue = (facetKey: string, selectedFacets: string[]) => {
    const currentKeyPrefix = facetKey.split(':')[0]
    return selectedFacets.find((key) => key.startsWith(`${currentKeyPrefix}:`)) ?? `${currentKeyPrefix}:default`
  }

  const createLocalizationKey = (facetDisplayName: string) => {
    facetDisplayName = handleFacetEditionLocalization(facetDisplayName)
    return trimSpace(replaceNonXmlWithSpace(facetDisplayName))
  }

  const handleFacetEditionLocalization = (facetDisplayName: string) => {
    switch (facetDisplayName) {
      case FIRST:
        return 'First'
      case SECOND:
        return 'Second'
      case THIRD:
        return 'Third'
      case FOURTH:
        return 'Fourth'
      case FIFTH:
        return 'Fifth'
      case SIXTH:
        return 'Sixth'
      case SEVENTH:
        return 'Seventh'
      case EIGHTH:
        return 'Eighth'
      case NINTH:
        return 'Ninth'
      case TENTH:
        return 'Tenth'
      default:
        return facetDisplayName
    }
  }

  return (
    <div className="flex flex-col space-y-3">
      {facetGroups.map((group) => (
        <div className="pb-2" key={uid()}>
          <legend className="pb-4 text-sm font-semibold">{group.displayName}</legend>

          {group.displayMode === 1 && ( // Checkbox filters
            <>
              {group.facets.map((facet) => (
                <div key={uid()} className="flex flex-row items-start space-x-2 py-1">
                  <input
                    id={facet.key}
                    name={facet.facetName}
                    type="checkbox"
                    className="custom-checkbox mt-0.5"
                    onChange={() => handleCheckboxChange(facet.key)}
                    checked={selectedFacets.includes(facet.key)}
                  />
                  <label className="text-sm" htmlFor={facet.key}>
                    {t(`ProductListing.Facets.${createLocalizationKey(facet.displayName)}`)}
                  </label>
                </div>
              ))}
            </>
          )}

          {group.displayMode === 10 && ( // Dropdown filters
            <select
              className="text-md custom-select w-full"
              onChange={(e) => handleSelectChange(e.target.value)}
              value={getSelectValue(group.facets[0].key, selectedFacets)}
            >
              <option value={`${group.fieldName}:default`}></option>
              {group.facets.map((facet) => (
                <option key={uid()} value={facet.key}>
                  {t(`ProductListing.Facets.${createLocalizationKey(facet.displayName)}`)}
                </option>
              ))}
            </select>
          )}
        </div>
      ))}

      <SharedButton onClick={handleApplyFilters} style="primary">
        {localizations?.applyFilters}
      </SharedButton>

      {selectedFacets.length > 0 && (
        <div className="mx-1 mt-5">
          <SharedButton
            onClick={handleClearSelections}
            className="text-md inline-flex items-center space-x-3 font-semibold text-dark-blue-primary hover:underline"
          >
            {localizations?.clearSelections}
            <XMarkIcon className="ml-2 h-5 w-5" />
          </SharedButton>
        </div>
      )}
    </div>
  )
}

export default ProductListingFilters
