import { uid } from 'uid'
import { ProductListingPageLocalizations } from '../types/plp-types'

interface ProductListingSortProps {
  localizations: ProductListingPageLocalizations | undefined
  orderBy: number
  setOrderBy: (value: number) => void
  onOrderByChange: (newOrderBy: number) => void
}

const ProductListingSort: React.FC<ProductListingSortProps> = ({
  orderBy,
  onOrderByChange,
  setOrderBy,
  localizations,
}) => {
  const handleOrderByChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newOrderBy = parseInt(event.target.value)
    // let parent component handle URL updates by passing prop function up to parent
    onOrderByChange(newOrderBy)
    setOrderBy(newOrderBy)
  }

  return (
    <div className="flex flex-row justify-end pb-7">
      <div className="flex items-center">
        <label htmlFor="sort-tiles" className="flex items-center px-3 font-bold text-black">
          {localizations?.orderBy}
        </label>

        <select value={orderBy} id="sort-tiles" className="custom-select" onChange={handleOrderByChange}>
          {localizations?.orderByOptions?.map((el, i) => (
            <option key={uid()} value={i}>
              {el}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
export default ProductListingSort
