import React, { ButtonHTMLAttributes, forwardRef } from 'react'

type ButtonStyles = 'primary' | 'secondary' | 'outline' | 'text' | 'checkout'
type ButtonSize = 'large' | 'regular' | 'small'

export interface ButtonProps {
  htmlProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  type?: "submit" | "button" | "reset";
  size?: ButtonSize
  style?: ButtonStyles
  ariaLabel?: string
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  onClick?: () => void
  ref?: React.RefObject<any>
}

const handleActiveStyle = (style?: ButtonStyles, size?: ButtonSize) => {
  const btnGlobal =
    'inline-flex items-center justify-center text-base font-semibold cursor-pointer shared-transition focus:shadow-focus px-3 border-solid border rounded-lg'
  let btnSize
  let btnStyle

  if (style) {
    if (style) {
      if (style === 'secondary') {
        btnStyle = `bg-orange-primary text-grey-dark border-orange-primary 
                          hover:bg-orange-400 hover:text-grey-dark hover:border-orange-400 hover:no-underline
                          active:bg-orange-400 active:text-grey-dark active:border-orange-400
                          disabled:bg-grey-300 disabled:text-grey-light disabled:border-grey-300 disabled:cursor-not-allowed`
      } else if (style === 'outline') {
        btnStyle = `bg-white text-dark-blue-primary border-dark-blue-primary 
                          hover:bg-dark-blue-100 hover:no-underline
                          active:bg-dark-blue-100
                          disabled:bg-white disabled:text-grey-400 disabled:border-grey-400 disabled:cursor-not-allowed`
      } else if (style === 'text') {
        btnStyle = `bg-white text-dark-blue-400 border-transparent
                          hover:text-dark-blue-primary hover:no-underline
                          acitve:text-dark-blue-primary
                          disabled:text-grey-400 disabled:cursor-not-allowed`
      } else if (style === 'checkout') {
        btnStyle = `bg-white text-dark-blue-400 border-transparent
                          hover:text-dark-blue-primary hover:underline
                          acitve:text-dark-blue-primary
                          disabled:text-grey-400 disabled:cursor-not-allowed`
      }
      else {
        btnStyle = `bg-dark-blue-primary text-white border-dark-blue-primary 
                          hover:bg-dark-blue-400 hover:text-white hover:border-dark-blue-400 hover:no-underline
                          active:bg-dark-blue-400 active:text-white active:border-dark-blue-400
                          disabled:bg-grey-300 disabled:text-grey-light disabled:border-grey-300 disabled:cursor-not-allowed`
      }
    }

    if (size && size === 'large') {
      btnSize = 'py-3.5'
    } else if (size && size === 'small') {
      btnSize = 'py-2'
    } else {
      btnSize = 'py-2.5'
    }

    return `${btnGlobal} ${btnStyle} ${btnSize}`
  } else {
    return ''
  }
}

const SharedButton = forwardRef(function (props: Readonly<ButtonProps>, ref) {
  const { ariaLabel, size, style, children, disabled, className, onClick, htmlProps, type } =
    props
  return (
    <button
      type={type ?? "button"}
      aria-label={ariaLabel}
      className={`${handleActiveStyle(style, size)} ${className}`}
      disabled={disabled}
      onClick={onClick}
      ref={ref as React.RefObject<HTMLButtonElement>}
      {...htmlProps}
    >
      {children}
    </button>
  )
})

SharedButton.displayName = 'SharedButton'

export default SharedButton
