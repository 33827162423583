import Price from '@/components/shared/price/price'
import { currencyToLocale } from '@/components/shared/price/types/price-types'
import { DEFAULT_CURRENCY_CODE } from '@/lib/constants'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../loading-spinner'
import { OrderSummaryProps } from './types/order-summary-types'

const OrderSummary: React.FC<Readonly<OrderSummaryProps>> = (props: Readonly<OrderSummaryProps>) => {
  const { t } = useTranslation()
  const { onPage, isLoading, hasShipping, cartTotals, cartInfo } = props
  const culture = cartInfo?.currency ? currencyToLocale[cartInfo?.currency ?? DEFAULT_CURRENCY_CODE] : undefined
  const currency = cartInfo?.currency ?? ''

  // Centralize translations in a single object. All of these keys are mapped to locations in the backend
  const translations = {
    common: {
      free: t('Common.Free'),
    },
    summary: {
      title: t('Cart.Summary.Title', 'Summary'),
      total: t('Cart.Summary.Total', 'Total'),
      subtotal: t('Cart.Summary.Subtotal', 'Subtotal'),
      taxes: t('Cart.Summary.Taxes', 'Taxes'),
      shipping: t('Cart.Summary.Shipping', 'Shipping'),
      orderTotal: t('Cart.Summary.OrderTotal', 'Order Total'),
      estimatedTotal: t('Cart.Summary.EstimatedTotal', 'Estimated Order Total'),
    },
  }

  const shippingCost = (cartTotals?.shippingTotal ?? 0) + (cartTotals?.handlingTotal ?? 0)
  const isFreeShipping = shippingCost === 0
  const price = !isFreeShipping ? (
    <Price currency={currency} price={shippingCost.toString() ?? '0'} culture={culture} />
  ) : (
    translations.common.free
  )

  return (
    <div className="flex flex-col space-y-8 pb-[20px] pt-3 lg:space-y-14">
      <div className="flex flex-col space-y-4 md:space-y-8">
        <div className="text-xl font-bold text-grey-dark">{translations.summary.title}</div>
        {/* Cart view */}
        {onPage === 'cart' && (
          <div className="flex flex-col gap-2">
            <div className="text-bold flex flex-row justify-between text-3xl">
              <span>{translations.summary.total}</span>
              {isLoading ? (
                <div className="flex items-center text-right">
                  <LoadingSpinner className="h-[14px] w-[14px]" />
                </div>
              ) : (
                <span className="text-right">
                  <Price currency={currency} price={cartTotals?.subTotal.toString() ?? '0'} culture={culture} />
                </span>
              )}
            </div>
          </div>
        )}

        {/* Checkout view */}
        {onPage === 'checkout' && (
          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between">
              <span>{translations.summary.subtotal}</span>
              <span className="text-right">
                {isLoading ? (
                  <div className="flex items-center text-right">
                    <LoadingSpinner className="h-[14px] w-[14px]" />
                  </div>
                ) : (
                  <Price currency={currency} price={cartTotals?.subTotal.toString() ?? '0'} culture={culture} />
                )}
              </span>
            </div>
            <div className="flex flex-row justify-between">
              <span>{translations.summary.taxes}</span>
              <span className="text-right">
                {isLoading ? (
                  <div className="flex items-center text-right">
                    <LoadingSpinner className="h-[14px] w-[14px]" />
                  </div>
                ) : (
                  (cartTotals?.taxTotal ?? 0) !== 0 && (
                    <Price currency={currency} price={cartTotals?.taxTotal.toString() ?? '0'} culture={culture} />
                  )
                )}
              </span>
            </div>

            {hasShipping && (
              <div className="flex flex-row justify-between">
                <span>{translations.summary.shipping}</span>
                <span className="text-right">
                  {!isLoading ? (
                    price
                  ) : (
                    <div className="flex items-center text-right">
                      <LoadingSpinner className="h-[14px] w-[14px]" />
                    </div>
                  )}
                </span>
              </div>
            )}

            <div className="mt-[20px] flex flex-row justify-between">
              <span className="text-1xl font-bold">
                {hasShipping ? translations.summary.orderTotal : translations.summary.estimatedTotal}
              </span>
              <span className="text-right text-1xl font-bold">
                {isLoading ? (
                  <div className="flex items-center text-right">
                    <LoadingSpinner className="h-[14px] w-[14px]" />
                  </div>
                ) : (
                  <Price currency={currency} price={cartTotals?.total.toString() ?? '0'} culture={culture} />
                )}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

OrderSummary.defaultProps = {
  hasShipping: false,
}

export default OrderSummary
